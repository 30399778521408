import { guid } from '../../../server/helper'

// These enums must be synced with relevant SCSS variables
export const ADBLOCKER_ELEMENT_PLACEHOLDER = {
  BANNER_SPACE_ALLOCATOR: 'guid-ed6701d6',
  MOBILE_MODIFIER: 'guid-09574eba',
  TABLET_MODIFIER: 'guid-d327ac8d',
  DESKTOP_SM_MODIFIER: 'guid-d47a6a49',
  DESKTOP_SM2_MODIFIER: 'guid-84105d54',
  DESKTOP_SM3_MODIFIER: 'guid-15019ac5',
  DESKTOP_MD_MODIFIER: 'guid-aed24bc2',
  DESKTOP_LG_MODIFIER: 'guid-8798b7c9',
  DESKTOP_XL_MODIFIER: 'guid-b2e2aa92',
  ABOVE_DESKTOP_XL_MODIFIER: 'guid-b8e0710f',
  SPONSORED_ARTICLE_INDICATOR: 'guid-e7ed54a5'
}

const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE = {
  [ADBLOCKER_ELEMENT_PLACEHOLDER.BANNER_SPACE_ALLOCATOR]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.MOBILE_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.TABLET_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM2_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_SM3_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_MD_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_LG_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.ABOVE_DESKTOP_XL_MODIFIER]: null,
  [ADBLOCKER_ELEMENT_PLACEHOLDER.SPONSORED_ARTICLE_INDICATOR]: null
}

function generateUniqueId(existingIds = []) {
  let id
  do {
    id = `guid-${guid().match(/[\d\w]*/)[0]}`
  } while (existingIds.includes(id))

  return id
}

function fillDynamicValues(obj) {
  const usedIds = []
  const resultObject = {}

  for (const key in obj) {
    const uniqueId = generateUniqueId(usedIds)
    resultObject[key] = uniqueId
    usedIds.push(uniqueId)
  }

  return resultObject
}

export const ADBLOCKER_ELEMENT_DYNAMIC_VALUE_BY_PLACEHOLDER = fillDynamicValues(
  ADBLOCKER_ELEMENT_DYNAMIC_VALUE_TEMPLATE
)
